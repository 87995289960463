import { expressQuerySelector, expressQuerySelectorAll, getParentWithClassName, isMobileOnPageWithHeader } from "../common/html";
import { initPagedShowMoreElements, initShowMoreElements, ShowMoreClickedReturn } from "./show-more-elements";
import { createPopup } from "./popup-new";
import { getParameter, queryRemoveKeys, updateParameter } from "../utils/querystring";
import { createFilters, IFilters, sortGroupFilterValues } from "../organisms/filters";

export interface IProductGridDeps {
	showMoreClick?: (currentPage: number, sizeDesktop?: number, sizeMobile?: number, sortMethod?: string, selectedFilters?: { [inputName: string]: string[] }) => Promise<ShowMoreClickedReturn>; // returns all shown
	filterGrid?: (grid: HTMLElement, filterName: string, sortMethod: string, selectedFilters: { [inputName: string]: string[] }) => void;
	enableShowLess?: boolean;
}

export interface IProductGridOptions {
	refreshOnResize: boolean;
}

export interface IProductGrid {
	readonly resetFilter: () => void;
	readonly reFilter: (productGrid: HTMLElement) => void;
	readonly disableSort: (force: boolean) => void;
}

export function createProductGrid(grid: HTMLElement, deps: IProductGridDeps, opts?: IProductGridOptions) {
	const { showMoreClick, filterGrid } = deps;
	const filters: { mobile: IFilters, desktop: IFilters } = {
		mobile: null,
		desktop: null
	};
	const gridCounter = parseInt(grid.dataset['gridcounter'] || '', 10);
	const filteredValues: { [gridCount: string]: { [filterCategory: string]: string[] } } = {};
	let savedSortMethod: string;
	const regExpForNumberInParentheses = /\(([0-9]+)\)$/;
	let shouldGroupFilterValues = false;

	const calculateHackFor2TilesOnLastLine = (grid: HTMLElement): void => {
		const filters = expressQuerySelector(grid.parentElement, '.technical-filters-container', false);
		const tiles = expressQuerySelectorAll(grid, '.technical-tile:not(.u-hide-on-desktop-full):not(.u-hide)');

		if (tiles.length % (filters ? 3 : 4) == 2)
			grid.classList.add('m-product-grid--tail-2-tiles-on-desktop');
		else
			grid.classList.remove('m-product-grid--tail-2-tiles-on-desktop');
	};
	const refreshFiltersAndSortingOnWindowResize = () => {
		let isMobile = isMobileOnPageWithHeader();

		const onWindowResize = () => {
			const isMobileOnResize = isMobileOnPageWithHeader();
			if (isMobile !== isMobileOnResize) {
				document.location.reload();
				isMobile = isMobileOnResize;
			}
		};

		opts?.refreshOnResize && window.addEventListener('resize', onWindowResize);
	};
	const disableSort = (force: boolean) => {
		const sortingGrid = expressQuerySelector(grid, '.technical-sorting-container', false);
		if (sortingGrid) {
			const sortEl = expressQuerySelector(sortingGrid, '.technical-dropdown', false);
			if (sortEl) {
				grid.classList.toggle('a-form-input--disabled', force);
				sortEl.toggleAttribute('disabled', force);
			}
		}
	};
	const filterAndSortGrid = (grid: HTMLElement, filterName: string = null, sortMethod: string = null, selectedFilters: { [inputName: string]: string[] } = null): void => {
		savedSortMethod = sortMethod;

		if (filterGrid) {
			return filterGrid(grid, filterName, sortMethod, selectedFilters);
		}

		const gridCounter = parseInt(grid.dataset['gridcounter'] || '', 10);
		const gridContent = expressQuerySelector(grid, '.technical-product-grid-content', true);

		const tiles = expressQuerySelectorAll<HTMLElement>(grid, '.technical-tile');
		const resetTiles = filterName === 'reset' || (filteredValues[gridCounter] && (Object.keys(filteredValues[gridCounter]).every(x => !filteredValues[gridCounter][x] || filteredValues[gridCounter][x].length === 0) || Object.keys(filteredValues[gridCounter]).length === 0));

		// Reset all tiles
		tiles.forEach(tile => {
			tile.classList.remove("technical-showmore");
			tile.classList.remove("u-hide-on-desktop-full");
			tile.classList.remove("u-hide-on-mobile-full");
			if (resetTiles) tile.classList.remove('u-hide');
		});

		// Reset the show more
		const button = expressQuerySelector(grid, '.technical-showmore-button', false);
		button && button.classList.remove('u-hide');

		// Sort the tiles
		if (sortMethod) {
			const sortTile = (a: HTMLElement, b: HTMLElement, sortMethod: string) => {
				switch (sortMethod) {
					case "price-low-high":
						if (parseFloat(a.dataset.sortPrice) === parseFloat(b.dataset.sortPrice))
							return parseInt(a.dataset.sortDefault, 10) > parseInt(b.dataset.sortDefault, 10) ? 1 : -1;
						return parseFloat(a.dataset.sortPrice) > parseFloat(b.dataset.sortPrice) ? 1 : -1;
					case "price-high-low":
						if (parseFloat(a.dataset.sortPrice) === parseFloat(b.dataset.sortPrice))
							return parseInt(a.dataset.sortDefault, 10) > parseInt(b.dataset.sortDefault, 10) ? 1 : -1;
						return parseFloat(a.dataset.sortPrice) <= parseFloat(b.dataset.sortPrice) ? 1 : -1;
					case 'delivery-early-late':
						if (a.dataset.sortDate === b.dataset.sortDate)
							return parseInt(a.dataset.sortDefault, 10) > parseInt(b.dataset.sortDefault, 10) ? 1 : -1;
						return a.dataset.sortDate > b.dataset.sortDate ? 1 : -1;
					case "review-high-low": {
						const ratingA = a.dataset.reviewrating ? parseFloat(a.dataset.reviewrating) : -1;
						const ratingB = b.dataset.reviewrating ? parseFloat(b.dataset.reviewrating) : -1;

						const sortDefaultA = parseInt(a.dataset.sortDefault, 10);
						const sortDefaultB = parseInt(b.dataset.sortDefault, 10);

						return ratingA !== ratingB
							? ratingB - ratingA
							: sortDefaultA - sortDefaultB;
					}
					case "unit-price-low-high":
						{
							const unitPriceA = parseFloat(a.dataset.sortUnitPrice);
							const unitPriceB = parseFloat(b.dataset.sortUnitPrice);

							const sortDefaultA = parseInt(a.dataset.sortDefault, 10);
							const sortDefaultB = parseInt(b.dataset.sortDefault, 10);

							return unitPriceA === unitPriceB
								? sortDefaultA > sortDefaultB ? 1 : -1
								: unitPriceA > unitPriceB ? 1 : -1;
						}
					default:
						return parseInt(a.dataset.sortDefault, 10) > parseInt(b.dataset.sortDefault, 10) ? 1 : -1;
				}
			};

			tiles.sort((a, b) => { // This sort method will sort by the actual sortmethod BUT (see next comment)
				const statusA = a.dataset.status;
				const statusB = b.dataset.status;

				if (statusA == statusB)
					return sortTile(a, b, sortMethod);

				const isAUnavailable = statusA && statusA != 'available' && statusA != 'comingsoon';
				const isBUnavailable = statusB && statusB != 'available' && statusB != 'comingsoon';

				if (isAUnavailable && !isBUnavailable)
					return 1;

				if (!isAUnavailable && isBUnavailable)
					return -1;

				return sortTile(a, b, sortMethod);
			});


			// This will put back the immutable tiles (e.g. tiles with no price)
			if (sortMethod !== 'default' && sortMethod != 'review-high-low') {
				let wrongTileIndex = 999;
				while (wrongTileIndex >= 0) {
					wrongTileIndex = tiles.findIndex((value, index) => {
						const defaultIndex = parseInt(value.dataset.sortDefault, 10);
						const temporarilyUnavailable = expressQuerySelector(value, '.m-product__temporarily-unavailable', false);

						if (defaultIndex === index || temporarilyUnavailable)
							return false;

						if (sortMethod == 'delivery-early-late' && !value.dataset.sortDate)
							return true;
						else if ((sortMethod === 'price-high-low' || sortMethod === 'price-low-high') && (!value.dataset.sortPrice || parseFloat(value.dataset.sortPrice) == -1))
							return true;
					});

					if (wrongTileIndex >= 0) {
						const element = tiles[wrongTileIndex];
						tiles.splice(wrongTileIndex, 1);
						tiles.splice(parseInt(element.dataset.sortDefault, 10), 0, element);
					}
				}
			}

			const previousSortMethod = getParameter(`sortmethod[${gridCounter}]`);
			const showOriginalPrice = sortMethod != 'unit-price-low-high';
			const shouldSwitchPrices = sortMethod == 'unit-price-low-high' || (previousSortMethod == 'unit-price-low-high' && showOriginalPrice);

			// This will put back the tiles in the DOM
			tiles.forEach(tile => {
				//Show delivery when sorting by delivery-early-late
				const hideDeliveryDate = sortMethod != 'delivery-early-late';
				const deliveryElem = expressQuerySelector(tile, '.technical-deliverydate', false);
				deliveryElem && deliveryElem.classList.toggle('u-hide', hideDeliveryDate);

				// Switch price fields on tile
				const fromPriceLabelEl = expressQuerySelector<HTMLElement>(tile, '.technical-staring-from-price', false);
				const mainPrice = expressQuerySelector<HTMLElement>(tile, '.technical-main-tile-price', false);
				const strikedPriceEl = expressQuerySelector<HTMLElement>(tile, '.technical-striked-price', false);
				const detailPrice = expressQuerySelector<HTMLElement>(tile, '.technical-detail-price', false);

				const isStartingPrice = fromPriceLabelEl && fromPriceLabelEl.dataset.startingprice === 'true';
				const units = fromPriceLabelEl && parseInt(fromPriceLabelEl.dataset.units, 10);
				fromPriceLabelEl && fromPriceLabelEl.classList.toggle('u-hide', showOriginalPrice
					? !isStartingPrice
					: !isStartingPrice && units <= 0);

				if (shouldSwitchPrices && mainPrice && detailPrice) {
					const price = mainPrice.dataset.price;
					const altPrice = mainPrice.dataset.altPrice;
					mainPrice.innerHTML = mainPrice.innerHTML === price ? altPrice : price;

					const unitPrice = detailPrice.dataset.unitPrice;
					const unitPriceWithPrice = detailPrice.dataset.unitPriceWithPrice;
					detailPrice.innerHTML = detailPrice.innerHTML === unitPrice ? unitPriceWithPrice : unitPrice;

					if (strikedPriceEl) {
						const strikedUnitPrice = strikedPriceEl.dataset.strikedUnitPrice;
						const strikedPrice = strikedPriceEl.dataset.strikedPrice;
						strikedPriceEl.innerHTML = strikedPriceEl.innerHTML == strikedUnitPrice ? strikedPrice : strikedUnitPrice;
					}
				}

				//Only aplly class m-product__info--has-shipping when showing delivery
				const infoContentItems = expressQuerySelector(tile, '.technical-info-content-items', false);
				infoContentItems && infoContentItems.classList.toggle('m-product__info--has-shipping', !hideDeliveryDate);

				gridContent.appendChild(tile);
			});
		}

		// Only show tiles that have the specific filter value
		if (filteredValues[gridCounter] && Object.keys(filteredValues[gridCounter]).length > 0 && Object.keys(filteredValues[gridCounter]).some(x => filteredValues[gridCounter][x] && filteredValues[gridCounter][x].length > 0)) {
			tiles.forEach(tile => {
				if (!selectedFilters) return; // no filters => nothing to do
				const tags = tile.dataset.tagid.split('|');
				let validTile = true;

				Object.keys(selectedFilters).forEach(category => {
					const selectedFilterInCategory = selectedFilters[category];

					const categoryWithoutSuffix = category && category.split('[')[0];
					switch (categoryWithoutSuffix) {
						case 'Pr': {
							const price = selectedFilterInCategory[0]?.split('-');
							if (!price) break;
							const min = +price[0];
							const max = +price[1];
							const tileLowPrice = +tile.dataset.sortPrice;
							const tileHighPrice = +tile.dataset.priceHigh;

							const isValidLowPrice = tileLowPrice >= min && tileLowPrice <= max;
							const isValidHighPrice = tileHighPrice >= min && tileHighPrice <= max;
							const isValidBetweenLowPriceAndHighPrice = (min - tileLowPrice) > 0 && (tileHighPrice - max) > 0;

							validTile = validTile && (!selectedFilterInCategory.length || (isValidLowPrice || isValidHighPrice || isValidBetweenLowPriceAndHighPrice));
							break;
						}
						case 'Re': {
							const selectedReviewRating = +selectedFilterInCategory[0];
							if (!selectedReviewRating) break;

							const tileReviewRating = +tile.dataset.reviewrating;
							validTile = validTile && (!selectedFilterInCategory.length || (tileReviewRating && tileReviewRating >= selectedReviewRating));
							break;
						}
						default:
							validTile = validTile && (!selectedFilterInCategory.length || tags.filter(x => selectedFilterInCategory.includes(x)).length > 0);
							break;
					}
				});

				tile.classList.toggle("u-hide", !validTile);
			});
		}

		// all tiles hidden => show no elements label
		const noElementsEl = expressQuerySelector(gridContent, '.technical-no-elements', false);
		noElementsEl && noElementsEl.classList.toggle('u-hide', !tiles.every(t => t.classList.contains('u-hide')));
		disableSort(tiles.every(t => t.classList.contains('u-hide')));

		const showMore = expressQuerySelector<HTMLElement>(grid, '.technical-showmore-button', false);
		if (showMore) {
			if (resetTiles) {
				showMore.dataset.currentpage = '0';
				window.history.pushState({}, null, queryRemoveKeys(['currentpage[' + gridCounter + ']']));
			}

			const baseAmountDesktop = parseInt(showMore.dataset.baseamountDesktop, 10);
			const baseAmountMobile = parseInt(showMore.dataset.baseamountMobile, 10);
			const batchSizeDesktop = parseInt(showMore.dataset.batchsizeDesktop, 10);
			const batchSizeMobile = parseInt(showMore.dataset.batchsizeMobile, 10);

			const currentPage = parseInt(getParameter('currentpage[' + gridCounter + ']') || '0', 10);
			let hideButtonDesktop = true;
			let hideButtonMobile = true;

			const visibleTiles = tiles.filter(x => !x.classList.contains('u-hide'));
			for (let i = 0; i < visibleTiles.length; i++) {
				if (i >= baseAmountDesktop + (currentPage * batchSizeDesktop)) {
					visibleTiles[i].classList.add('u-hide-on-desktop-full');
					visibleTiles[i].classList.add('technical-showmore');
					hideButtonDesktop = false;
				}
				if (i >= baseAmountMobile + (currentPage * batchSizeMobile)) {
					visibleTiles[i].classList.add('u-hide-on-mobile-full');
					visibleTiles[i].classList.add('technical-showmore');
					hideButtonMobile = false;
				}
			}

			showMore.parentElement.classList.toggle('u-hide-on-desktop-full', hideButtonDesktop);
			showMore.parentElement.classList.toggle('u-hide-on-mobile-full', hideButtonMobile);

			if (window.showMores[gridCounter])
				window.showMores[gridCounter].dispose();
			window.showMores[gridCounter] = initPagedShowMoreElements(grid, showMore, 'currentpage', null, gridCounter, showMoreClick ? (currentPage: number) => showMoreClick(currentPage, null, null, sortMethod, selectedFilters) : null, () => calculateHackFor2TilesOnLastLine(grid));
		}

		// Recount all the filter values except for the filter that was just clicked
		if (filterName) {
			const filters = expressQuerySelectorAll<HTMLElement>(grid.parentElement, '.technical-filter');
			filters.forEach(filter => {
				const filterValues = expressQuerySelectorAll<HTMLElement>(filter, '.technical-filter-value');
				const ownCategory = filter.dataset.filterqs;
				filterValues.forEach(filterValue => {
					const label = expressQuerySelector<HTMLElement>(filterValue, 'label', true);
					const labelText = expressQuerySelector<HTMLElement>(label, '.technical-label', true);
					const input = expressQuerySelector<HTMLInputElement>(filterValue, 'input', true);

					if (filterName === ownCategory) {
						const shouldFilterBeDisabled = !input.checked && (labelText.innerHTML.includes('(0)'));
						filterValue.classList.toggle('a-form-input--disabled', shouldFilterBeDisabled);
						input.toggleAttribute('disabled', shouldFilterBeDisabled);
						return;
					}

					const ownValue = filterValue.dataset.filterkey;
					const filtersToCheck = { ...selectedFilters };
					filtersToCheck[ownCategory] = !filtersToCheck[ownCategory] || filtersToCheck[ownCategory].length <= 0
						? [ownValue]
						: [...filtersToCheck[ownCategory], ownValue];
					const filterKeys = Object.keys(filtersToCheck);

					// if only filter selected => default valid tiles count
					const validTiles = filterValue.dataset.default_tile_count && filterKeys.every(x => (x != ownCategory && filtersToCheck[x].length == 0) || (x == ownCategory && filtersToCheck[x].length > 0))
						? parseInt(filterValue.dataset.default_tile_count)
						: tiles.filter(tile => {
							return tile.classList.contains('u-hide') // u-hide is toggled on in filter step above
								? false
								: tile.dataset.tagid.split('|').includes(ownValue) || (+tile.dataset.reviewrating && +ownValue && +tile.dataset.reviewrating >= +ownValue);
						}).length;

					const shouldFilterBeDisabled = validTiles == 0 && !input.checked;
					filterValue.classList.toggle('a-form-input--disabled', shouldFilterBeDisabled);
					input.toggleAttribute('disabled', shouldFilterBeDisabled);

					const html = labelText.innerHTML;
					labelText.innerHTML = html.replace(regExpForNumberInParentheses, "(" + validTiles + ")");
				});
			});

			shouldGroupFilterValues && filters.forEach(filter => {
				if (filter.dataset.typeoffilter === "Slider" || filter.classList.contains('technical-ignore-groupfiltervalues')) return;
				const filterValues = expressQuerySelectorAll<HTMLElement>(filter, '.technical-filter-value');
				sortGroupFilterValues(filterValues, filter);
			});
		}

		calculateHackFor2TilesOnLastLine(grid);

		if (sortMethod) {
			let url = location.href;
			if (sortMethod === 'default')
				url = updateParameter('sortmethod[' + gridCounter + ']', '', url);
			else
				url = updateParameter('sortmethod[' + gridCounter + ']', sortMethod, url);
			if (url != location.href)
				history.replaceState(null, '', url.toLowerCase());
		}

		// update tile counter
		const gridAmount = expressQuerySelector<HTMLElement>(grid, '.technical-amount-of-tiles', false);
		if (!gridAmount) return; // when html is wrong in cache this element is not there
		const visibleTiles = tiles.filter(x => !x.classList.contains('u-hide')).length;
		gridAmount.innerHTML = visibleTiles > 1
			? (gridAmount.dataset.multi || '').replace('{0}', visibleTiles.toString())
			: visibleTiles <= 0 ? '' : (gridAmount.dataset.single || '');
		gridAmount.classList.toggle('u-hide', visibleTiles <= 0);
	};
	const refilter = (productGrid: HTMLElement) => {
		const gridCounter = parseInt(productGrid.dataset['gridcounter'] || '', 10);
		filterAndSortGrid(productGrid, null, null, filteredValues[gridCounter]);
	};
	const filterCreateForOneGrid = (filterContainer: HTMLElement): { resetFiltersContainerEl: () => void } => {
		const onFilter = (multiselect: boolean, filterName: string, filterValue: string, checked: boolean, containerEl: HTMLElement, updateValue?: boolean) => {
			const gridToFilter = expressQuerySelector<HTMLElement>(getParentWithClassName(containerEl, 'technical-grid-and-filters'), '.technical-product-grid', true);
			const gridCounter = parseInt(gridToFilter.dataset['gridcounter'] || '', 10);
			if (!filteredValues[gridCounter])
				filteredValues[gridCounter] = {};

			const previousSelectedValue = filteredValues[gridCounter][filterName];
			if (!multiselect && checked)
				filteredValues[gridCounter][filterName] = [];
			const filteredOutValue = filteredValues[gridCounter][filterName] && filteredValues[gridCounter][filterName].find(x => x == filterValue);
			filteredValues[gridCounter][filterName] = (!filteredValues[gridCounter][filterName] || filteredValues[gridCounter][filterName].length <= 0) && checked
				? [filterValue]
				: checked
					? [...filteredValues[gridCounter][filterName], filterValue]
					: filteredOutValue ? filteredValues[gridCounter][filterName].filter(x => x != filterValue) : []; // case filteredOutValue false => happens with slider(old value new value)

			const currentValue = filteredValues[gridCounter][filterName];
			// only update when called and there are differences in the selected filter
			if (updateValue && previousSelectedValue?.toString() !== currentValue?.toString())
				filterAndSortGrid(gridToFilter, filterName, null, filteredValues[gridCounter]);
		};

		const resetFilter = (containerEl: HTMLElement) => {
			// This resets the filter
			const gridToFilter = expressQuerySelector<HTMLElement>(getParentWithClassName(containerEl, 'technical-grid-and-filters'), '.technical-product-grid', true);
			const gridCounter = parseInt(gridToFilter.dataset['gridcounter'] || '', 10);
			filteredValues[gridCounter] = {};
			filterAndSortGrid(gridToFilter, 'reset', null, filteredValues[gridCounter]);
		};

		return createFilters(filterContainer, { onFilter, resetAll: resetFilter });
	};
	if (!grid.classList.contains('technical-load-api')) {
		const buttonEl = expressQuerySelector<HTMLElement>(grid.parentElement ? grid.parentElement : document, '.technical-showmore-button', false);
		if (buttonEl)
			window.showMores[gridCounter] = initPagedShowMoreElements(grid, buttonEl, 'currentpage', null, gridCounter, showMoreClick ? (currentPage: number, sizeDesktop: number, sizeMobile: number) => showMoreClick(currentPage, sizeDesktop, sizeMobile, savedSortMethod, filteredValues[gridCounter]) : null, () => calculateHackFor2TilesOnLastLine(grid));

		// filter?
		const filterContainer = expressQuerySelector<HTMLElement>(grid.parentElement ? grid.parentElement : document, '.technical-filters-container', false);
		if (filterContainer) {
			shouldGroupFilterValues = filterContainer.dataset.groupfiltervalues === 'true';

			// Desktop
			filters.desktop = filterCreateForOneGrid(filterContainer);

			// Mobile
			const mobileButton = expressQuerySelector<HTMLElement>(grid, '.technical-filters-mobile-button', true);
			const popup = expressQuerySelector<HTMLElement>(document, '#MobileFilters' + gridCounter, true);
			const mobileContainer = expressQuerySelector<HTMLElement>(popup, '.technical-filters-container', true);
			filters.mobile = filterCreateForOneGrid(mobileContainer);

			const onClickMobile = () => {
				createPopup({
					dialogId: 'MobileFilters' + gridCounter,
					view: 'none'
				}).then(popup => {
					if (!popup.popup) return;
					popup.openAsync();
					const button = expressQuerySelector(popup.popup, '.technical-close-dialog', true);
					button.addEventListener('click', popup.close);
				});
			};
			mobileButton.addEventListener('click', onClickMobile);

			// Mobile sorting
			const sortElsMobile = expressQuerySelectorAll<HTMLInputElement>(mobileContainer, '.technical-sorting-radio');
			sortElsMobile.forEach(sortElMobile => {
				sortElMobile.addEventListener('click', () => {
					filterAndSortGrid(grid, null, sortElMobile.value, filteredValues[gridCounter]);
				});
			});
		}

		const gridContent = expressQuerySelector(grid, '.technical-product-grid-content', true);
		gridContent.classList.remove('skeleton-loading');
		const gridAmount = expressQuerySelector(grid, '.technical-amount-of-tiles', false);
		gridAmount && gridAmount.classList.remove('skeleton-loading');

		// sorting?
		const sortEl = expressQuerySelector<HTMLSelectElement>(grid, '.technical-dropdown', false);
		if (sortEl) {
			sortEl.addEventListener('change', () => {
				const selectedSortingValue = sortEl[sortEl.selectedIndex] as HTMLOptionElement;
				filterAndSortGrid(grid, null, selectedSortingValue.value, filteredValues[gridCounter]);
			});
		}

		refreshFiltersAndSortingOnWindowResize();

		calculateHackFor2TilesOnLastLine(grid);

		const resetAllFilters = () => {
			filters.mobile.resetFiltersContainerEl();
			filters.desktop.resetFiltersContainerEl();
		};

		return {
			resetFilter: filters && resetAllFilters,
			reFilter: refilter,
			disableSort
		} as IProductGrid;
	}
}
